import styled from 'styled-components/macro';

export const CustomGroupCheckboxWrapper = styled.div`
  .ant-checkbox-group {
    display: flex;
    flex-direction: column;
    .ant-checkbox-wrapper {
      margin-bottom: 0.5rem;
    }
  }
`;
