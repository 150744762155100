import { FC, SVGAttributes } from 'react';

export const SuccessIcon: FC<SVGAttributes<HTMLOrSVGElement>> = props => {
  return (
    <svg
      width="40px"
      height="12px"
      viewBox="0 0 12 14"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        id="Design"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Icons" transform="translate(-1760.000000, -331.000000)">
          <g
            id="Notification---Check-Mark"
            transform="translate(1756.000000, 328.000000)"
          >
            <rect id="Rectangle" x="0" y="0" width="20" height="20"></rect>
            <path
              d="M15.3111373,3.6851593 C15.776299,3.91255135 15.9860752,4.45310004 15.8106239,4.92861397 L15.7703588,5.02273756 L10.9373693,14.9092833 C10.2905653,16.2324102 8.69362078,16.7806781 7.37049396,16.133874 C7.13937881,16.0208945 6.92613557,15.8752191 6.73721613,15.7016021 L6.60018807,15.5662247 L4.35529308,13.1821175 C3.97668455,12.7800304 3.9957177,12.1471515 4.39780479,11.768543 C4.77308608,11.415175 5.34941262,11.4081972 5.732411,11.735749 L5.81137925,11.8110547 L8.05627425,14.1951619 C8.11138497,14.2536902 8.17662723,14.3017682 8.24885072,14.3370743 C8.55418768,14.4863367 8.91785266,14.3810277 9.09917662,14.1037743 L9.14056956,14.0309266 L13.973559,4.14438081 C14.2161105,3.64820825 14.8149647,3.44260778 15.3111373,3.6851593 Z"
              id="Path"
              fill="#FFFFFF"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
