export const MOMENT_FORMAT = {
  MONTH_FORMAT: 'MM/YYYY',
  FOOD_CREATED_ON: 'MM/DD/YYYY',
  FULL_DATE_FORMAT: 'DD-MM-YYYY',
  FULL_DATE_FORMAT_WITH_SLASH: 'MM/DD/YYYY',
  TIME_ONLY: 'HH:mm',
  DOB: 'DD/MM/YYYY',
  EXPORT: 'YYYYMMDD_hhmmss',
};
export const SORT_TYPE = {
  DESC: 'DESC',
  ASC: 'ASC',
};
export const MAX_SCREEN_WIDTH = 1440;

export enum DATE_PICKER_TYPES {
  DATE = 'date',
  WEEK = 'week',
  MONTH = 'month',
  QUARTER = 'quarter',
  YEAR = 'year',
}

export enum Entities {
  ADDRESS = 'ADDRESS',
}

export enum PROFILE_TAB {
  USER = 'user',
  TEAM = 'team',
}

export enum MODAL_WIDTH {
  MEDIUM = 500,
  SMALL = 360,
}
