import { FC, SVGAttributes } from 'react';

export const InfoIcon: FC<SVGAttributes<HTMLOrSVGElement>> = () => {
  return (
    <svg
      width="40px"
      height="18px"
      viewBox="0 0 14 18"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>20px - Notification - White@1.5x</title>
      <g
        id="Design"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Icons" transform="translate(-1723.000000, -329.000000)">
          <g
            id="Notification---Attention"
            transform="translate(1720.000000, 328.000000)"
          >
            <rect id="Rectangle" x="0" y="0" width="20" height="20"></rect>
            <path
              d="M13.0804213,16.275904 C12.9114784,16.6833268 12.6640605,17.0499847 12.3570226,17.3570226 C11.7538079,17.9602373 10.9204746,18.3333333 10,18.3333333 C9.07952542,18.3333333 8.24619208,17.9602373 7.6429774,17.3570226 C7.3359395,17.0499847 7.0885216,16.6833268 6.91957875,16.275904 L6.91957875,16.275904 Z M10,1.66666667 C10.8467674,1.66666667 11.5460462,2.29814042 11.6526065,3.11585797 C12.3872311,3.49193328 13.0338668,4.02736012 13.5416667,4.6875 L13.7479695,4.95569366 C14.2959769,5.66810336 14.6549978,6.50748578 14.7916667,7.39583333 L14.9030975,8.12013362 C14.967605,8.5394324 15,8.96304495 15,9.38727682 L15,11.1529466 C15,11.6136282 15.1906804,12.0537629 15.5267648,12.368842 L16.4032843,13.190579 C16.5713264,13.3481185 16.6666667,13.5681859 16.6666667,13.7985267 L16.6666667,14.21875 C16.6666667,14.6502225 16.3168891,15 15.8854167,15 L4.11458333,15 C3.68311087,15 3.33333333,14.6502225 3.33333333,14.21875 L3.33333333,13.7985267 C3.33333333,13.5681859 3.42867356,13.3481185 3.59671573,13.190579 L4.47323521,12.368842 C4.80931955,12.0537629 5,11.6136282 5,11.1529466 L5,9.38727682 C5,8.96304495 5.03239501,8.5394324 5.09690252,8.12013362 L5.20833333,7.39583333 C5.34500219,6.50748578 5.70402306,5.66810336 6.25203052,4.95569366 L6.45833333,4.6875 C6.96613901,4.02735262 7.61278356,3.49192111 8.34851361,3.11660673 C8.45395378,2.29814042 9.15323259,1.66666667 10,1.66666667 Z"
              id="Shape"
              fill="#FFFFFF"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};
